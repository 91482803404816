<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_5">
      램 메일 수신 동의<router-link to="" @click.native="$router.back()"
        ><img src="@/assets/images/sub/close.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="terms_common">
      <h4>램 메일 발송방침</h4>

      <!---->
      <p>
        리뷰앤메이크머니(주)는 회사가 회원에게 제공하는 서비스를 보다 원활히
        하기 위해 필요한 경우 램 회원에게 메일을 보낼 수 있습니다. 회원의
        개인정보 수정이나, 상품/서비스 거래, 리뷰어 채널 등록, 판매자 채널 등록,
        개인정보처리 안내, 회사의 정책 변경 안내 등등 회사는 램 회원이 램의
        서비스를 이용하는 각 단계마다 안내 메일을 보낼 수 있으며, 이는 이용자의
        편의를 증진시키기 위한 조치들입니다. 이런 조치들 외에, 회사는 램
        이용자에게 광고성 메일을 보내지 않습니다. 다만, 회사 정책상 필요에 의해
        회사가 광고성 메일을 보낼 경우 회사는 이용자의 동의를 얻어 이를
        시행합니다.
      </p>
      <p></p>
    </div>

    <!---->
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:image", property: "og:image", content: "" },
      ],
    };
  },
};
</script>

<style></style>
